<template>
  <v-row class="login-row" no-gutters>
    <v-col class="d-flex justify-center align-center" cols="12" md="5">
      <v-scroll-x-transition leave-absolute hide-on-leave>
        <router-view/>
      </v-scroll-x-transition>
    </v-col>
    <v-col cols="12" md="7">
      <div
        class="d-flex flex-column align-center justify-center"
        :class="checkMd ? 'image-right' : 'image-bottom'"
      >
        <div class="my-auto"></div>
        <v-icon class="mt-auto" :size="checkSm ? 325 : 180">$logoIcon</v-icon>
        <div class="d-flex my-auto pb-10">
          <v-icon class="mr-10" @click="goToLinkedin">$linkedinkIcon</v-icon>
          <v-icon @click="goToFacebook">$facebookIcon</v-icon>
        </div>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import AuthTabs from '@/components/tabs/AuthTabs'

export default {
  name: 'Auth',
  components: { AuthTabs },
  data: () => ({}),
  computed: {
    checkMd() {
      return this.$vuetify.breakpoint.width > 1029
    },
    checkSm() {
      return this.$vuetify.breakpoint.width > 599
    },
  },
  methods: {
    goToFacebook() {
      window.open('https://www.facebook.com/Dialics-109364704630086', '_blank')
    },
    goToLinkedin() {
      window.open('https://www.linkedin.com/company/dialics', '_blank')
    },
  },
}
</script>
