<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'AuthTabs',
  inheritAttrs: false,
}
</script>
